const portfolio = [
	{
		name: 'Peter Dobias',
		description: 'Revitalizing Pet Health E-commerce: The Dr. Peter Dobias Success Story',
		internalUrl: '/work/peter-dobias',
		externalUrl: 'https://peterdobias.com/',
		featuredImage: 'peter-dobias',
		hasCaseStudy: true,
		hasPage: true,
	},
	{
		name: 'Fyne Jewellery',
		description: 'Design, Custom Shopify Components, Store and Speed Optimisation.',
		internalUrl: '/work/fyne-jewellery',
		externalUrl: 'https://fynejewellery.com',
		featuredImage: 'fyne-jewellery',
		hasCaseStudy: true,
		hasPage: true,
	},
	{
		name: 'Hugh Maguire',
		description: 'Bespoke Shopify Theme Design, WooCommerce Migration and CRO.',
		internalUrl: '/work/hugh-maguire',
		externalUrl: 'https://hughmaguirebutchers.com',
		featuredImage: 'hugh-maguire',
		hasCaseStudy: true,
		hasPage: true,
	},
	{
		name: 'A&S Market',
		description: 'Bespoke Theme Development, Custom Functionalities, Additional Apps Integration.',
		internalUrl: '/work/as-market',
		externalUrl: 'https://www.asmarket.co.uk',
		featuredImage: 'as-market',
		hasCaseStudy: true,
		hasPage: true,
	},
	{
		name: 'TOPL Cup',
		description: 'Design & Custom Theme Development, Personalisation Tool and Hubspot Integration.',
		internalUrl: '/work/topl-cup',
		externalUrl: 'https://toplcup.com',
		featuredImage: 'topl-cup',
		hasCaseStudy: true,
		hasPage: true,
	},
	{
		name: 'SavinSkin',
		description: 'Custom Shopify Theme with Additional Components & Functionalities.',
		internalUrl: '/work/savin-skin',
		externalUrl: 'https://savinskin.com',
		featuredImage: 'savinskin',
		hasCaseStudy: false,
		hasPage: true,
	},
	{
		name: 'Verlin',
		description: 'OpenCart to Shopify Migration, Design and Custom Pages & Components.',
		internalUrl: '/work/verlin',
		externalUrl: 'https://verlin.ro',
		featuredImage: 'verlin',
		hasCaseStudy: false,
		hasPage: true,
	},
	{
		name: 'Lovyt',
		description: 'New Shopify Store Setup, Apps Integration and Additional Components',
		internalUrl: '/work/lovyt',
		externalUrl: 'https://lovyt.com',
		featuredImage: 'lovyt',
		hasCaseStudy: false,
		hasPage: true,
	},
	{
		name: 'Folkingtons',
		description: 'New Flexible, Scalable and Reusable Components based on new Design.',
		internalUrl: '/work/folkingtons',
		externalUrl: 'https://www.folkingtons.co.uk',
		featuredImage: 'folkingtons',
		hasCaseStudy: false,
		hasPage: true,
	},
	{
		name: 'Margee',
		description: 'Complete Website Revamp on 2.0 Theme, New Navigation and Custom Filtering.',
		internalUrl: '/work/margee',
		externalUrl: 'https://margee.com',
		featuredImage: 'margee',
		hasCaseStudy: false,
		hasPage: false,
	},
	{
		name: 'Evcoms',
		description: 'Wordpress Brochure Website, Woocommerce Integration and Job Application Form Service.',
		internalUrl: '/work/evcoms',
		externalUrl: 'https://www.evcoms.com',
		featuredImage: 'evcoms',
		hasCaseStudy: false,
		hasPage: false,
	},
	{
		name: 'Ramona Albert',
		description: 'Technical & Content SEO, Shopify Maintenance, 3D Models Integration.',
		internalUrl: '/work/ramona-albert',
		externalUrl: 'https://ramonaalbert.shop',
		featuredImage: 'ramona-albert',
		hasCaseStudy: false,
		hasPage: false,
	},
];

export default portfolio;
